import React from "react";
import Ample from "../components/ample/ample";
import {useSiteMetadata} from "../branding";


const TermsOfServicePage = (props) => {
    const siteMetadata = useSiteMetadata();
    const seoDescription="Terms of service";
    return (
        <Ample currPageTitle="Terms of Service" seoDescription={seoDescription} location={props.location.href}>
            <div className="card">
            <div className="card-body">
                <h3 className="card-title">Not Investment Advice</h3>
                {siteMetadata.author} is providing this site for educational purposes only. It should not be considered legal, accounting, or financial advice. It is not a solicitation or recommendation to buy or sell any stocks, options, or other financial investments.
            </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <h3 className="card-title">Investment Risks</h3>  
                Trading or investing whether on margin or otherwise carries a high level of risk. It is possible to sustain a loss of some, or even more than, your initial investment. Use of the information on this website or materials linked from the web is at your own risk. You should be aware of all the risks associated with trading and investing, and seek advice from an independent financial advisor before deciding to trade or invest.
                </div>
            </div>

            <div className="card">
            <div className="card-body">
                <h3 className="card-title">Third Party Linked Sites</h3>
                This site may include links to third-party web sites operated by third parties. 
                {siteMetadata.author} has no control over these sites or their content.  
                {siteMetadata.author} is not responsible for the availability of such external sites or their content, and "SwaggyStocks" does not adopt, endorse or nor is responsible or liable for any such sites or content, on or available through such sites. 
                {siteMetadata.author} does not endorse such sites and shall not be responsible or liable for any content, advertising, products or other materials available on or through such other sites, 
                 or any loss or damages incurred in connection therewith. 
                </div>
            </div>

            
            <div className="card">
            <div className="card-body">
                <h3 className="card-title">Site and Content Not Warranted</h3>
                The site and content, are provided as-is and without warranties of any kind. 
                You bear all risks associated with the use of the site and content.
                </div>
            </div>

            <div className="card">
            <div className="card-body">
                <h3 className="card-title">Limiation of Liability</h3>
                Your exclusive remedy for dissatisfaction with the site and content is to stop using the site.
                {siteMetadata.author} is not liable for any direct, indirect, or incidental, damages associated with the use of, or inability to use, this site or content. 
                </div>
            </div>

            <div className="card">
            <div className="card-body">
                <h3 className="card-title">Limited Right of Use/Ownership of Content</h3>
                You are permitted to use the Site and Content for your personal, non-commercial use only. The Site and Content are and shall remain the property of {siteMetadata.author}. 
                You may use the Site and Content for your personal, noncommercial use, provided that you keep intact all copyright notices. 
                You agree not to reproduce, modify or create derivative works based on, rent, lease, loan, sell, distribute, publish, publicly perform or display, reverse engineer, de-compile or dissemble, all or any part of the ste or content. 
                </div>
            </div>

            <div className="card">
            <div className="card-body">
                <h3 className="card-title">Indemnification</h3>
                By accessing and using the Site and Content, you agree to indemnify, defend and hold harmless {siteMetadata.author} from and against any and all claims, losses, costs and expenses  arising out of or relating to (a) any breach (or claim, that if true, would be a breach) by you of these Terms and (b) your use of or activities in connection with the Site.               
                </div>
            </div>
        </Ample>);
 
};
export default TermsOfServicePage;